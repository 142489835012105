import React, { useState } from 'react'
import { Button } from '../button'
import { Modal, SanitizationPresets, TrustedHtml } from 'shared'
import { ModalTriggerProps } from './types'

export const ModalWithTrigger = ({
  cssClass,
  trigger,
  title,
  children,
  buttonTriggered,
  content = '',
  footerContent = '',
  size,
  closeButtonText,
  initialShow = 'false',
  id,
}: ModalTriggerProps): JSX.Element => {
  const [showModal, setShowModal] = useState(initialShow == 'true')

  const hideModal = () => setShowModal(false)
  const openModal = () => {
    setShowModal(true)
  }

  return (
    <>
      {buttonTriggered ? (
        <button className={cssClass || 'btn btn-large btn-danger'} onClick={openModal}>
          {trigger}
        </button>
      ) : (
        <button type="button" className={cssClass || 'simulated-link'} onClick={openModal}>
          {trigger}
        </button>
      )}

      <Modal
        id={id}
        onHide={hideModal}
        show={showModal}
        header={title}
        size={size}
        footer={
          <>
            <TrustedHtml sanitizationPreset={SanitizationPresets.MissingPreset} as="div">
              {footerContent}
            </TrustedHtml>
            {closeButtonText ? (
              <Button onClick={hideModal} className="btn-large btn-primary">
                {closeButtonText}
              </Button>
            ) : null}
          </>
        }
      >
        <TrustedHtml as="div">{content}</TrustedHtml>
        {children}
      </Modal>
    </>
  )
}
